import camelCase from "camelcase";
import { getPublicEnv } from "~/helpers";
import { UserInterfaceSetting } from "../types";
import { TFunction } from "i18next";
import { ReturnOrderResponse, ShopTranslations } from "./types";
import { UserInterfaceTranslations } from "~/types/shopData";

export const parseClientSubmissionErrors = (translations: ShopTranslations, clientSubmissionErrors?: string) => {
  if (clientSubmissionErrors === "Load failed") {
    return translations.typeError.loadFailed;
  } else {
    return clientSubmissionErrors;
  }
};

export const getText = (text: UserInterfaceTranslations | string, locale: string, defaultText: string = "") => {
  switch (typeof text) {
    case "object":
      return text[camelCase(locale)] || defaultText; // title object from rails
    default:
      return defaultText; // 360 defaults
  }
};

export function getTranslations(
  userInterfaceSetting: UserInterfaceSetting,
  locale: string,
  t: TFunction,
): ShopTranslations {
  const {
    orderIdLabelTranslations,
    orderIdPlaceholderTranslations,
    orderEmailPhoneLabelTranslations,
    orderEmailPhonePlaceholderTranslations,
    startButtonNameTranslations,
  } = userInterfaceSetting;

  return {
    orderIdLabel: getText(orderIdLabelTranslations, locale, t("customerInfo.orderId")),
    orderIdPlaceholder: getText(orderIdPlaceholderTranslations, locale),
    orderEmailPhoneLabel: getText(orderEmailPhoneLabelTranslations, locale, t("customerInfo.emailOrPhone")),
    orderEmailPhonePlaceholder: getText(orderEmailPhonePlaceholderTranslations, locale),
    startButtonName: getText(startButtonNameTranslations, locale, t("customerInfo.submit")),
    poweredBy: t("poweredBy"),
    agreement: t("customerInfo.agreement"),
    returnsPolicy: t("customerInfo.returnsPolicy"),
    returningAGift: t("customerInfo.returningAGift"),
    typeError: { loadFailed: t("typeError.loadFailed") },
  };
}

export function getNextStepPath(
  request: Request,
  returnOrderResponse: ReturnOrderResponse,
  orderId: string,
  emailOrPhone: string,
  locale: string,
) {
  const { returnOrder, nextStep } = returnOrderResponse;

  const nextStepToRouteMap: Record<string, string> = {
    stageOneQuestions: "stage_one_questions",
    returnItems: "return_items_selection",
  };

  let nextStepPath;
  switch (returnOrder.status) {
    case "draft":
      nextStepPath = nextStepToRouteMap[nextStep];
      break;
    case "pending_payment_on_submission":
      nextStepPath = "submission_payment";
      break;
    default:
      nextStepPath = "details";
      break;
  }

  const url = request.url;

  const shopSlug = new URL(url).pathname.split("/")[1];

  let oldCustomerAppUrl, newCustomerAppUrl;
  try {
    oldCustomerAppUrl = new URL(getPublicEnv("OLD_CUSTOMER_APP_URL") || "");
    newCustomerAppUrl = new URL(getPublicEnv("NEW_CUSTOMER_APP_URL") || "");
  } catch (error) {
    throw new Error("OLD_CUSTOMER_APP_URL or NEW_CUSTOMER_APP_URL is not set");
  }
  let appUrl = nextStepPath === "submission_payment" ? newCustomerAppUrl : oldCustomerAppUrl;

  const isEmbeddedFromUrl = isEmbedded(request.url);

  // navigate to new app if localhost, comment this out if you want to test production env
  if (appUrl.host.includes("localhost")) {
    if (["submission_payment", "stage_one_questions"].includes(nextStepPath)) {
      appUrl = newCustomerAppUrl;
    }
  }

  const { host, protocol } = appUrl;
  const hostIsLocalhost = host.includes("localhost");

  const embeddedHost = hostIsLocalhost ? `embed.${host}` : `embed-${host}`;
  const updatedHost = isEmbeddedFromUrl ? embeddedHost : host;
  let queryParams = new URLSearchParams({
    orderNameOrId: orderId, // TODO: remove this param when we fully migrated to v2 customer app.
    orderIdOrName: orderId, // new param for v2 customer app
    emailOrPhone,
    locale,
  }).toString();
  const basePath = `${protocol}//${updatedHost}/${shopSlug}/returns/${returnOrder.id}`;

  const finalUrl = `${basePath}/${nextStepPath}?${queryParams}`;

  return finalUrl;
}

export function getMobileEmbededRedirectPath(
  request: Request,
  returnOrderResponse: ReturnOrderResponse,
  orderId: string,
  emailOrPhone: string,
  locale: string,
) {
  const { returnOrder, nextStep } = returnOrderResponse;
  const nextStepToRouteMap: Record<string, string> = {
    stageOneQuestions: "stage_one_questions",
    returnItems: "return_items_selection",
  };
  let nextStepPath;
  switch (returnOrder.status) {
    case "draft":
      nextStepPath = nextStepToRouteMap[nextStep];
      break;
    case "pending_payment_on_submission":
      nextStepPath = "submission_payment";
      break;
    default:
      nextStepPath = "details";
      break;
  }
  const url = request.url;
  const shopSlug = new URL(url).pathname.split("/")[1];
  const oldCustomerAppUrl = new URL(getPublicEnv("OLD_CUSTOMER_APP_URL") || "");
  const newCustomerAppUrl = new URL(getPublicEnv("NEW_CUSTOMER_APP_URL") || "");
  const appUrl = nextStepPath === "submission_payment" ? newCustomerAppUrl : oldCustomerAppUrl;
  const queryParams = new URLSearchParams({
    orderNameOrId: orderId,
    emailOrPhone,
    mobileEmbedded: "true",
    locale,
  }).toString();
  const basePath = `${appUrl.origin}/${shopSlug}/returns/${returnOrder.id}`;

  return `${basePath}/${nextStepPath}?${queryParams}`;
}

export function isEmbedded(url: string) {
  // example url: http://localhost:8081/nic-farm-us?embed=true or http://embed.localhost:8081/nic-farm-us
  const urlObject = new URL(url);
  // checking from search params is left here for backwards compatibility
  const isEmbeddedFromUrl = urlObject.searchParams.get("embed") === "true";
  const isEmbeddedFromSubdomain = urlObject.hostname.split(".")[0]?.includes("embed");

  return isEmbeddedFromUrl || isEmbeddedFromSubdomain;
}
